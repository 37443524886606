import React from "react";
import {
  Box,
  Heading,
  Text,
  Link,
  ListItem,
  OrderedList,
  Center,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const WAPrivacyPolicy = () => {
  return (
    <Center p={8}>
      <VStack maxW="1100px" alignItems={"flex-start"} spacing={6} w="full">
        <Heading as="h1" size="xl">
          Privacy policies for public projects
        </Heading>
        <Text>Last updated: June 2, 2024</Text>

        <Accordion allowToggle w="full" allowMultiple defaultIndex={[0]}>
          <AccordionItem>
            <AccordionButton py={4}>
              <HStack flex="1" textAlign="left" justify={"space-between"}>
                <Heading size="md">WeakAura Saver Chrome Extension</Heading>
                <Button
                  mr={4}
                  size="sm"
                  rightIcon={<ExternalLinkIcon />}
                  as="a"
                  href="https://chromewebstore.google.com/detail/jddhepnflgpcdmcmodhceabhjkbpicef"
                  target="_blank"
                >
                  Download
                </Button>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>
                Thank you for using the WeakAura Saver Chrome Extension ("the
                Extension"). Your privacy is of utmost importance to us. This
                privacy policy outlines the type of information the Extension
                does not collect, share, or store, and explains the measures we
                take to ensure your data remains private.
              </Text>

              <Heading as="h3" size="md" my={4}>
                No Data Collection
              </Heading>
              <Text>
                The Extension is designed to assist users in saving WeakAura
                strings from the Wago.io website to a local text file. We want
                to be clear that:
              </Text>
              <OrderedList>
                <ListItem>
                  <Text>
                    No Personal Data Collection: The Extension does not collect
                    any personal data or any other information from you.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    No Usage Data Collection: The Extension does not track or
                    collect usage data or any other metrics.
                  </Text>
                </ListItem>
              </OrderedList>

              <Heading as="h3" size="md" my={4}>
                No Data Sharing
              </Heading>
              <Text>We do not share any data for any reason:</Text>
              <OrderedList>
                <ListItem>
                  <Text>
                    No Data Sharing with Third Parties: Since the Extension does
                    not collect any data, it follows that we do not share any
                    data with third parties.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    No Data Sharing for Advertising: We do not collect or share
                    data for advertising purposes.
                  </Text>
                </ListItem>
              </OrderedList>

              <Heading as="h3" size="md" my={4}>
                No Data Storage
              </Heading>
              <Text>The Extension does not store any data:</Text>
              <OrderedList>
                <ListItem>
                  <Text>
                    Local Storage Only: Any data generated by the Extension
                    (such as WeakAura strings) is only stored locally on your
                    device in the form of a text file that you save. This data
                    is never transmitted over the internet.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    No Cloud Storage: The Extension does not use any form of
                    cloud storage or online databases to store data.
                  </Text>
                </ListItem>
              </OrderedList>

              <Heading as="h3" size="md" my={4}>
                How the Extension Works
              </Heading>
              <Text>The Extension performs the following functions:</Text>
              <OrderedList>
                <ListItem>
                  <Text>
                    Clipboard Access: The Extension reads the clipboard content
                    only when you initiate a save action. This content is used
                    solely for the purpose of saving it to a text file.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    File Saving: The Extension uses the File System Access API
                    to allow you to save the clipboard content to a text file on
                    your local device. This process is entirely managed locally
                    and no data is sent over the internet.
                  </Text>
                </ListItem>
              </OrderedList>

              <Heading as="h3" size="md" my={4}>
                Changes to This Privacy Policy
              </Heading>
              <Text>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </Text>

              <Heading as="h3" size="md" my={4}>
                Contact Us
              </Heading>
              <Text>
                If you have any questions about this Privacy Policy, please
                contact us at{" "}
                <Link href="mailto:emesinternet@gmail.com" color="teal.500">
                  emesinternet@gmail.com
                </Link>
                .
              </Text>

              <Text my={4}>
                By using the Extension, you agree to the terms outlined in this
                privacy policy. Thank you for trusting WeakAura Saver with your
                data privacy.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </Center>
  );
};

export default WAPrivacyPolicy;
